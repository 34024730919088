<template>
	<div>
		<CCard class="p-1">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow style="color: #1352a1;">
						<CCol lg="6">
							<h5 v-if="!editMode">Create Work Order</h5>
							<h5 v-else>Update Work Order</h5>
						</CCol>
						<CCol lg="6">
							<div style="text-align: right">
								<CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()">Cancel</CButton>
								<CButton class="mr-1" :disabled="odoHasError" size="sm" @click="draft()" color="success"><i
										class="fa fa-save"></i> Save</CButton>
								<CButton class="mr-1" size="sm" color="info" id="btn-submit" type="submit"
									style="display: none;">Hidden</CButton>
								<CButton class="mr-1" :disabled="odoHasError" active-class="" size="sm" color="info"
									type="submit" v-if="!this.editMode"><i class="fa fa-save"></i> Submit</CButton>
							</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
					<CCard class="p-3 mt-0">
						<CRow class="p-1 mb-2 bg-secondary text-dark">
							<CCol lg="6">
								<strong style="font-size:12px"> Work Order Details</strong>
							</CCol>
						</CRow>
						<br />
						<CRow>
							<CCol lg="12" md="12" sm="12">
								<div class="form-group">
									<label for="asset_type">Asset Type<span class="text-danger"> *</span>
									</label>
									<CInputRadioGroup :options="[
										{
											value: 'vehicle',
											label: 'Vehicle'
										},
										{
											value: 'equipment',
											label: 'Equipment'
										},
										{
											value: 'furnishing',
											label: 'Furnishing'
										}
									]" :checked.sync="dataParams.asset_type" :inline="true" required="true" />
								</div>
							</CCol>
						</CRow>
						<CRow class="mb-2">
							<CCol lg="4" md="12" sm="12">
								<label v-if="dataParams.asset_type !== 'vehicle'">Asset No. <span class="text-danger">*</span></label>
								<label v-else>Plate No. <span class="text-danger">*</span></label>
								<v-select label="asset_no" placeholder="-Select-" :options="furnishingList.data"
									:reduce="item => item.id" v-if="dataParams.asset_type == 'furnishing'"
									@input="changeAsset(item)" v-model="dataParams.furnishing_id">
									<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'furnishing'">
										<input class="vs__search"
											:required="!dataParams.furnishing_id && dataParams.asset_type == 'furnishing'"
											v-bind="attributes" v-on="events" />
									</template>
								</v-select>
								<v-select label="asset_no" placeholder="-Select-" :options="equipmentList.data"
									:reduce="item => item.id" @input="changeAsset(index)" v-model="dataParams.equipment_id"
									v-if="dataParams.asset_type == 'equipment'">
									<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'equipment'">
										<input class="vs__search"
											:required="!dataParams.equipment_id && dataParams.asset_type == 'equipment'"
											v-bind="attributes" v-on="events" />
									</template>
								</v-select>
								<v-select label="plate_no" placeholder="-Select-" :options="vehicleList.data"
									:reduce="item => item.id" @input="changeAsset" v-model="dataParams.vehicle_id"
									v-if="dataParams.asset_type == 'vehicle'">
									<template #search="{ attributes, events }" v-if="dataParams.asset_type == 'vehicle'">
										<input class="vs__search"
											:required="!dataParams.vehicle_id && dataParams.asset_type == 'vehicle'"
											v-bind="attributes" v-on="events" />
									</template>
								</v-select>
							</CCol>
							<CCol lg="4" md="12" sm="12" v-if="dataParams.vehicle_id">
								<label v-if="dataParams.asset_type == 'vehicle'">Odometer<span
										class="text-danger"> *</span></label>
								<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
								<CInput 
									v-mask="'######'"
									required 
									type="number" 
									:class="{ 'has-error': odoHasError }"
									:disabled="validating_odometer" 
									v-model="dataParams.odo_reading"
									@change="odometerChanged" 
									v-if="dataParams.asset_type == 'vehicle'" />
								<div v-if="dataParams.asset_type == 'vehicle'" class="mb-3"
									style="margin-top: -5px; font-size:11px ">
									<span>Current Odometer:
										<span v-if="!data_loading.odometer">{{ dataParams.current_odometer ?
											dataParams.current_odometer : 0 }}</span>
										<span v-else><i class="fa fa-spin fa-loading"></i> Fetching ....</span>
									</span>
								</div>
							</CCol>
							<CCol lg="4" md="12" sm="12" v-if="dataParams.asset_type == 'vehicle' && dataParams.vehicle_id">
								<label>Asset No. </label>
								<CInput disabled v-model="asset_no_display.asset_no" />
							</CCol>
						</CRow>
						<CRow class="mb-2">
							<CCol lg="12" md="12" sm="12">
								<div class="form-group">
									<label>Work Order Type <span class="text-danger">*</span></label>
									<v-select label="setting_name" :options="workorderTypeFormat" :reduce="item => item.id" v-model="dataParams.wo_type" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.wo_type" v-bind="attributes"
												v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="6" md="12" sm="12">
								<div class="form-group">
									<label>Date Needed
										<span class="text-danger"> *
										</span>
									</label>

									<Datepicker @selected="dateSelected" input-class="form-control bg-white"
										v-model="dataParams.date_needed" :typeable="true" required>
									</Datepicker>
								</div>
							</CCol>
							<CCol lg="6" md="12" sm="12">
								<div class="form-group">
									<label>Requested By <span class="text-danger">*</span></label>
									<v-select label="name" :options="personnellist.data" :reduce="item => item.id"
										v-model="dataParams.requested_by">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.requested_by"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>


						</CRow>
						<CRow class="mb-2">
							<CCol lg="6">
								<label>Start Date</label>
								<Datepicker input-class="form-control bg-white" v-model="dataParams.start_date"
									:typeable="true">
								</Datepicker>
							</CCol>
							<CCol lg="6">
								<label>Finish Date</label>
								<Datepicker input-class="form-control bg-white" v-model="dataParams.finish_date"
									:typeable="true">
								</Datepicker>
							</CCol>

						</CRow>
						<CRow>
						</CRow>
						<CRow>
							<CCol lg="6" md="12" sm="12">
								<div class="form-group">
									<label>Dept / Proj </label>
									<v-select label="customer_name" :options="customerList.data" :reduce="item => item.id"
										v-model="dataParams.dept_id" @input="locationChanged($event)">
									</v-select>
								</div>

							</CCol>
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Depot</label>
									<CInput v-if="dataParams.depot" disabled v-model="dataParams.depot.setting_name" />
									<CInput v-else disabled />

								</div>
							</CCol>

						</CRow>
						<CRow>


							<CCol lg="4" md="12" sm="12">
								<div class="form-group">
									<label>Assigned To <span class="text-danger">*</span></label>
									<CInputRadioGroup :options="[
										{
											value: 'company',
											label: 'Company'
										},
										{
											value: 'subcon',
											label: 'Subcon'
										}

									]" :checked.sync="dataParams.assigned_to" :inline="true" required="true" />
								</div>
							</CCol>
							<CCol lg="8" md="12" sm="12">
								<div class="form-group" v-if="dataParams.assigned_to == 'subcon'">
									<label>Subcon (Vendor) <span class="text-danger">*</span></label>
									<v-select label="vendor_name" :options="vendorList.data" :reduce="item => item.id"
										v-model="dataParams.vendor_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.vendor_id" v-bind="attributes"
												v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12" md="12" sm="12">
								<label>Service Summary</label>
								<CTextarea v-model="dataParams.service_summary" />

							</CCol>
						</CRow>
					</CCard>
					<CCard class="p-3 mt-0">

						<!-- LABOR REQUEST -->
						<CRow class="p-2 mb-1 bg-secondary text-dark">
							<CCol lg="12">
								<strong style="font-size:12px"> Labor Request</strong>
							</CCol>
							<!-- <CCol lg="3" class="text-right">
							<label class="d-flex justify-content-between " style="font-weight:600">
							Labor Total:
							<strong>{{labor_total |  number_format('currency')}} </strong>
							</label>
							</CCol> -->
						</CRow>
						<br>
						<CRow v-for="(item, index) in dataParams.work_order_labor_request">
							<CCol lg="12" md="12" sm="12">
								<label>Description <span class="text-danger">*</span></label>
								<CInput v-model="dataParams.work_order_labor_request[index].description" required />

							</CCol>
							<CCol lg="5" md="12" sm="12">
								<div class="form-group">
									<label>Service Type<span class="text-danger">*</span></label>

									<v-select label="setting_name" :options="serviceTypeFormat" :reduce="item => item.id" v-model="dataParams.work_order_labor_request[index].service_type">
										<template #search="{ attributes, events }">
											<input class="vs__search"
												:required="!dataParams.work_order_labor_request[index].service_type"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>

							<CCol lg="2" md="12" sm="12">
								<label>Qty <span class="text-danger">*</span></label>
								<CInput @input="changeQty(index)" v-model="dataParams.work_order_labor_request[index].qty"
									:required="true" type="number" />
							</CCol>
							<CCol lg="2" md="12" sm="12">
								<label>Cost <span class="text-danger">*</span></label>
								<CInput @input="changeQty(index)" v-model="dataParams.work_order_labor_request[index].unit"
									:required="true" @keypress="isNumber($event)" />
							</CCol>
							<CCol lg="2" md="12" sm="12">
								<label>Total</label>
								<CInput
									v-model="dataParams.work_order_labor_request[index].unit * dataParams.work_order_labor_request[index].qty"
									placeholder="0.00" disabled />
							</CCol>
							<CCol lg="1" md="12" sm="12" v-if="$route.params.id ? true : index != 0">
								<br />
								<CButton size="sm" color="danger mt-2" @click="deleteItem(index)"><i
										class="fa fa-trash"></i></CButton>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="2" md="12" sm="12">
								<CButton size="sm" color="success" @click="addItem"><i class="fa fa-plus"></i> New Item
								</CButton>
							</CCol>
						</CRow>
						<br>
						<!--  PARTS REQUEST -->

						<CRow class="p-2 mb-1 bg-secondary text-dark">
							<CCol lg="12">
								<strong style="font-size:12px"> Part Request</strong>
							</CCol>

						</CRow>
						<br>
						<CRow v-for="(item, index) in dataParams.work_order_part_request">

							<CCol lg="5" md="12" sm="12">
								<div class="form-group">
									<label>Description</label>
									<v-select label="part_name" :options="partlist.data" :reduce="item => item.id"
										v-model="dataParams.work_order_part_request[index].part_inventory_id"
										@input="changeDesc(index)">

									</v-select>
								</div>
							</CCol>
							<CCol lg="2" md="12" sm="12">
								<label>Qty </label>
								<CInput @input="changeQty(index)" @keypress="isNumber($event)"
									v-model="dataParams.work_order_part_request[index].qty" />
							</CCol>
							<CCol lg="2" md="12" sm="12">
								<label>Cost</label>
								<CInput @input="changeQty(index)" @keypress="isNumber($event)"
									v-model="dataParams.work_order_part_request[index].unit" />
							</CCol>
							<CCol lg="2" md="12" sm="12">
								<label>Total</label>
								<CInput
									v-model="dataParams.work_order_part_request[index].unit * dataParams.work_order_part_request[index].qty"
									placeholder="0.00" disabled />
							</CCol>
							<CCol lg="1" v-if="$route.params.id ? true : index != 0">
								<br />
								<CButton size="sm" color="danger mt-2" @click="deletePartItem(index)"><i
										class="fa fa-trash"></i></CButton>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="2">
								<CButton size="sm" color="success" @click="addPartItem"><i class="fa fa-plus"></i> New Item
								</CButton>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="9" class="text-right"></CCol>
							<CCol lg="3" class="text-left">
								<h6>Cost Summary</h6>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="9" class="text-right"></CCol>
							<CCol lg="3" class="text-right">
								<ul class="list-group list-group-flush">
									<li class="list-group-item d-flex justify-content-between align-items-center py-1"
										style="font-size:12px">
										Labor
										<strong>{{ labor_total | number_format(null, 2) }}</strong>
									</li>
									<li class="list-group-item d-flex justify-content-between align-items-center py-1"
										style="font-size:12px">
										Part
										<strong>{{ part_total | number_format(null, 2) }}</strong>
									</li>

									<li class="list-group-item d-flex justify-content-between align-items-center"
										style="font-weight:700; font-size:18px">
										TOTAL
										<strong> {{ computeTotal | number_format(null, 2) }} </strong>
									</li>

								</ul>

							</CCol>
						</CRow>
					</CCard>
					<CCard class="p-3 mt-0">
						<!-- REFERENCES-->
						<CRow class="p-2 mb-2 bg-secondary text-dark">
							<CCol lg="12">
								<strong style="font-size:12px"> References </strong>
							</CCol>

						</CRow>
						<CRow>
							<CCol lg="12">
								<CInput label="Remarks" v-model="dataParams.remarks" />
							</CCol>

						</CRow>
						<CRow>
							<CCol lg="3">
								<CInput label="SRF No." v-model="dataParams.srf_no" />
							</CCol>
							<CCol lg="3">
								<CInput label="P.O No." v-model="dataParams.po_no" />
							</CCol>
							<CCol lg="3">
								<CInput label="Inv. No." v-model="dataParams.inv_no" />
							</CCol>

						</CRow>
						<CRow>
							<CCol lg="3">
								<CInput label="Performed By" v-model="dataParams.performed_by" />
							</CCol>
							<CCol lg="3">
								<CInput label="Checked By" v-model="dataParams.checked_by" />
							</CCol>
							<CCol lg="3">
								<CInput label="Confirmed By" v-model="dataParams.confirmed_by" />
							</CCol>
							<CCol lg="3">
								<CInput label="Noted By" v-model="dataParams.noted_by" />
							</CCol>

						</CRow>



					</CCard>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script>
import axios from '../axios';
import axioss from 'axios';
import config from '../config.js';
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';

export default {
	mounted() {

		this.getCustomer();
		this.getEquipment();
		this.getFurnishing();
		this.getVehicle();
		this.getPersonnelList();
		this.getvendorList();
		this.getpartsList();
		this.getDepotList();


		if (this.$route.params.id != undefined) {
			this.editMode = true;

		}
		if (this.$route.params.id) {
			this.getData();



		}

	},
	data() {
		return {
			isDraft: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			odo_reading: '',
			validating_odometer: false,
			odo_error: false,
			data_loading: {
				odometer: false
			},
			dataParams: {
				start_date: "",
				finish_date: "",
				performed_by: "",
				checked_by: "",
				confirmed_by: "",
				noted_by: "",
				// odometer: "",
				odo_reading: "",
				equipment_id: "",
				vehicle_id: "",
				furnishing_id: "",
				requested_by: "",
				subcon_id: "",
				asset_type: "vehicle",
				asset_no: "",
				wo_type: "",
				location: "",
				service_summary: "",
				date_needed: "",
				remarks: "",
				srf_no: "",
				po_no: "",
				inv_no: "",
				status: "",
				wo_no: "",
				dept_id: "",
				assigned_to: "company",
				vendor_id: "",
				work_order_labor_request: [
					{
						description: "",
						unit: "",
						qty: "",
						total: 0,
						service_type: "",

					}
				],
				work_order_part_request: [
					{
						part_inventory_id: "",
						unit: "",
						qty: "",
						total: 0,
					}
				]
			},
			editMode: false,
			selectedRow: {},
			vendorList: {
				data: []
			},

			customerList: {
				data: []
			},


			vehicleList: {
				data: []
			},

			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			depotList: {
				data: []
			},
			personnellist: {
				data: []
			},
			vendorList: {
				data: []
			},
			partlist: {
				data: []
			},

			isLoading: false,

			config,
			moment,
			labor_total: parseFloat(0).toFixed(2),
			part_total: parseFloat(0).toFixed(2),
			asset_no_display: '-',
			workorder_type: [
				'Preventive Maintenance',
				'Repair',
				'Change',
				'Dismantling',
				'Installation',
				'Checking & Diagnose',
				'Rehabilitation',
				'Recondition',
				'Others',
			],
			service_type: [
				'PM Service',			
				'Change Oil',		
				'Brakes',		
				'Aircon',		
				'Transmission',		
				'Re-eclectric wiring',
				'Overhaul',
				'Repainting',
				'Check Up/Diagnose',
				'Troubleshoot & Repair Malfunction',
				'Fabrication/Modification',
				'Overhaul (Engine, Transmission)',
				'Dismantling/Installation',
				'Tune Up/Adjustments',
				'Tire Replacement',
				'Electrical Wiring',
				'Rehab/Repair',
				'Fabricate',
				'Relocation',
			],
			drp_options : [
				"Erection of F0/F3 Luffing Crane w/ 4 Section Mast",
				"Erection of H3/QTD 22BB/S200LF10 w/ 4 Section Mast",
				"Jack-Up per Mast",
				"Jack-Up per Floor",
				"Jack-Down per Mast",
				"Jack-Down per Floor",
				"Dismantling of F0/F3 Luffing Crane w/ 4 Section Mast",
				"Dismantling of H3/QTD 22BB/S200LF10 w/ 4 Section Mast",
				"Foundation Setting",
				"Setting of Internal Collar",
				"Cutting and Removing of Internal Collar per set",
				"Installation of Internal Bracket per set",
				"Assembly of Tower Crane mast per set",
				"Reeving of Hoisting & Luffing/Trolley Cable and also Calibration of Limit Switches",
				"Replacement of Hoisting Cable",
				"Replacement of Luffing Cable",
				"Replacement of Trolley Cable (One side only)",
				"Electrical Troubleshooting per call",
				"Mechanical Troubleshooting per call",
				"Repair/Replacement of parts depending on the gravity damage",
				"Retrival of Fixing Angle",
				"Mounting of Telescopic Cage",
				"Dismounting of Telescopic Cage",
				"Assemble of Pivot/Turn Cable",
				"Dismantling of Monocrete Signage",
				"Installation of Monocrete Signage",
				"Splicing of 1 set beam for internal/external bracket",
				"Re-arrange of luffing cable",
				"Re-arrange of hoisting cable",
				"Dismantling of mast using derrick crane",
				"Reeving of hoisting cable to 2 lines",
				"Erection",
				"Installation of External Bracket per set",
				"Dismantling of Passenger Hoist w/ the aid of Tower Crane",
				"Dismantling of External Bracket",
				"Dismantling per section of mast w/the aid of Tower Crane",
				"Dismantling per section of mast (self dismantling)",
				"Erection of Derrick Crane",
				"Reeving of Hoisting & Luffing Cable of Derrick Crane",
				"Dismantling of Derrick Crane" ,
				"Dismantling of Jib Pole",
				"Erection of Jib Pole",
				"Installation of Dowels (per set)",
				"After 3hrs of waiting time change per hour per crew",
				"Cancellation",
				"Setting of Internal Collar Double I Beam",
				"Fly out of I Beam & Collar",
				"Cutting/Removal Internal Collar Double I Beam",
				"Erection of Placing Boom",
				"Dismantling of Placing Boom",
				"Setting of Internal collar(Standard)",
				"Cutting/Remove & Fly-out internal collar",
				"Setting of temporary internal collar",
				"Cutting temporary internal collar",
				"Dismantling of hydraulic cylinder for PB" 
			]

		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect },
	methods: {
		getDepotList() {

			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'depot'
				}
			})
				.then(response => {
					this.depotList = response.data;
					let depot_id = this.user_data?.depot_id;
					if (depot_id) {
						let depot = this.depotList.data.filter(item => item.id == depot_id);
						if (depot.length) {
							this.$set(this.dataParams, 'depot', depot[0])

							this.dataParams.depot_id = depot_id;
						}
					}
				})

		},
		locationChanged(ev) {
			let depot = null, depot_id = '';
			let customer = this.customerList.data.find(item => item.id == ev);
			if (customer) {
				depot_id = customer.depot_id;
			}
			depot = this.depotList.data.find(item => item.id == depot_id);
			console.log(depot)

			if (depot) {
				this.dataParams.depot = depot;
				this.dataParams.depot_id = depot.id;
			}
			else {
				this.dataParams.depot = null;
				this.dataParams.depot_id = null;
			}
		},

		draft() {
			this.isDraft = true;
			setTimeout(() => {
				document.getElementById('btn-submit').click();
			}, 500)
		},
		backToTable() {
			this.$router.push('/main/work_order')
		},
		getData() {
			this.$showLoading(true)

			axios.get(config.api_path + "/workOrder/" + this.$route.params.id)
				.then(response => {
					this.$showLoading(false)
					this.dataParams = response.data.data

					this.dataParams.date_needed = this.dataParams.date_needed = moment.unix(this.dataParams.date_needed).format('MM/DD/YYYY')
					if (this.dataParams.start_date) {
						this.dataParams.start_date = this.dataParams.start_date = moment.unix(this.dataParams.start_date).format('MM/DD/YYYY')
					}
					if (this.dataParams.finish_date) {
						this.dataParams.finish_date = this.dataParams.finish_date = moment.unix(this.dataParams.finish_date).format('MM/DD/YYYY')
					}
					this.changeAsset(this.dataParams.vehicle_id);

					this.dataParams.odo_reading = response.data.data.odometer_reading?.reading;

					let part_total = 0, labor_total = 0;
					var part_request = this.dataParams.work_order_part_request;
					for (var i = 0; i < part_request.length; i++) {
						part_total += parseFloat(part_request[i].qty * part_request[i].unit);
					}
					this.part_total = part_total.toFixed(2);

					var labor_request = this.dataParams.work_order_labor_request;
					for (var i = 0; i < labor_request.length; i++) {
						labor_total += parseFloat(labor_request[i].qty * labor_request[i].unit);
					}
					this.labor_total = labor_total.toFixed(2);
				})
				.catch(err => {
					console.error(err)
					this.$showLoading(false)
				})

		},
		getCustomer() {

			axios.get(config.api_path + '/reference/customer-list', {
				params: {

				}
			})
				.then(response => {
					this.customerList = response.data;

				})

		},
		addItem() {
			this.dataParams.work_order_labor_request.push({
				description: "",
				unit: 0,
				qty: 0,
				total: 0,
				service_type: "",

			})
		},

		addPartItem() {
			this.dataParams.work_order_part_request.push({
				part_inventory_id: "",
				unit: 0,
				qty: 0,
				total: 0,
			})
		},

		deleteItem(index) {
			this.dataParams.work_order_labor_request.splice(index, 1);
		},
		deletePartItem(index) {
			this.dataParams.work_order_part_request.splice(index, 1);
		},

		submit() {

			// if(this.isLoading){return;} 
			console.log(this.dataParams)
			var ax = {};

			if (this.dataParams.equipment_id == "") {
				this.dataParams.equipment_id = null;
			}

			// if(this.dataParams.odometer == ""){
			// 	this.dataParams.odometer = null;
			// }

			if (this.dataParams.furnishing_id == "") {
				this.dataParams.furnishing_id = null;
			}

			if (this.dataParams.vehicle_id == "") {
				this.dataParams.vehicle_id = null;
			}

			if (this.dataParams.dept_id == "") {
				this.dataParams.dept_id = null;
			}

			if (this.dataParams.subcon_id == "") {
				this.dataParams.subcon_id = null;
			}

			if (this.dataParams.vendor_id == "") {
				this.dataParams.vendor_id = null;
			}

			if (this.dataParams.part_location_id == "") {
				this.dataParams.part_location_id = null;
			}

			if (this.dataParams.depot_id == "") {
				this.dataParams.depot_id = null;
			}
			// if(this.dataParams.depot_id != ""){
			// 	formData.append('depot_id', this.dataParams.depot_id);
			// }  

			for (var i = 0; i < this.dataParams.work_order_labor_request.length; i++) {
				this.dataParams.work_order_labor_request[i].total = this.dataParams.work_order_labor_request[i].qty * this.dataParams.work_order_labor_request[i].unit
			}

			for (var i = 0; i < this.dataParams.work_order_part_request.length; i++) {
				this.dataParams.work_order_part_request[i].total = this.dataParams.work_order_part_request[i].qty * this.dataParams.work_order_part_request[i].unit
			}

			this.dataParams.date_needed = moment(this.dataParams.date_needed, "MM/DD/YYYY").startOf('day').valueOf() / 1000;

			if (this.dataParams.start_date) {
				this.dataParams.start_date = moment(this.dataParams.start_date, "MM/DD/YYYY").startOf('day').valueOf() / 1000;
			}
			else {
				this.dataParams.start_date = null;
			}
			if (this.dataParams.finish_date) {
				this.dataParams.finish_date = moment(this.dataParams.finish_date, "MM/DD/YYYY").startOf('day').valueOf() / 1000;
			} else {
				this.dataParams.finish_date = null;
			}

			if (this.editMode) {
				ax = axios.put(config.api_path + "/workOrder/" + this.$route.params.id, this.dataParams)
			}
			else {
				if (this.isDraft) {
					this.dataParams.status = "draft";
				}
				ax = axios.post(config.api_path + "/workOrder", this.dataParams);
			}
			ax.then(response => {
				var text = 'Work order successfully added!';

				if (this.editMode) {
					text = 'Work order successfully updated!';
				}

				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
				this.formModal = false
				this.editMode = false;
				this.isDraft = false;
				this.dataParams = {
					start_date: "",
					finish_date: "",
					performed_by: "",
					checked_by: "",
					confirmed_by: "",
					noted_by: "",
					odo_reading: "",
					// odometer: "",
					equipment_id: "",
					vehicle_id: "",
					furnishing_id: "",
					requested_by: "",
					subcon_id: "",
					asset_type: "vehicle",
					wo_type: "",
					location: "",
					service_summary: "",
					date_needed: "",
					remarks: "",
					srf_no: "",
					po_no: "",
					inv_no: "",
					status: "",
					wo_no: "",
					dept_id: "",
					assigned_to: "",
					vendor_id: "",
					work_order_labor_request: [
						{
							description: "",
							unit: 0,
							qty: 0,
							total: 0,
							service_type: "",

						}
					],
					part_inventory_id: [
						{
							part_inventory_id: "",
							unit: 0,
							qty: 0,
							total: 0,
						}
					]
				}
				this.$router.push('/main/view_work_order/' + response.data.data.id)
			})
		},




		getEquipment() {

			axios.get(config.api_path + '/reference/equipment-list')
				.then(response => {
					this.equipmentList = response.data;
					this.equipmentList.data = this.equipmentList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},
		getFurnishing() {

			axios.get(config.api_path + '/reference/furnishing-list')
				.then(response => {
					this.furnishingList = response.data;
					this.furnishingList.data = this.furnishingList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},

		getVehicle() {

			axios.get(config.api_path + '/reference/vehicle-list', {
				params: {
					// with_odometer:'1'
				}
			})
				.then(response => {
					this.vehicleList = response.data;
				})




			// axios.get(config.api_path+'/vehicle?page=1&limit=10000000&with_odo=true')
			// .then(response => {
			// 	let data = response.data.data;
			// 	let holder = [];
			// 	data.forEach(element => {
			// 		if(element.plate_no && element.plate_no !== 'null') holder.push(element)
			// 	});
			// 	this.vehicleList.data = holder;
			// 	this.vehicleList.data = this.vehicleList.data.map((value, index)=>{
			// 		value.value = value.id;
			// 		value.label = value.plate_no;
			// 		return value;
			// 	})
			// })
		},
		getPersonnelList() {

			axios.get(config.api_path + '/reference/personnel-list')
				.then(response => {
					this.personnellist = response.data;
					this.personnellist.data = this.personnellist.data.filter((value, index) => {
						value.value = value.id;
						return value;
					})
				})

		},
		getpartsList() {

			axios.get(config.api_path + '/partInventory?page=1&limit=10000000')
				.then(response => {
					this.partlist = response.data;
					this.partlist.data = this.partlist.data.map((value, index) => {
						value.value = value.id;
						value.label = value.part_name;
						return value;
					})
				})

		},

		getvendorList() {
			axios.get(config.api_path + '/reference/vendors-list')
				.then(response => {
					this.vendorList = response.data;
				})

			//  getvendorList(){

			// 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
			// 	.then(response => {
			// 		this.vendorList = response.data;


			// 	})

		},

		changeAsset(i) {
			console.log('INDEX', i)
			if (this.dataParams.asset_type == 'vehicle') {
				this.data_loading.odometer = true;
				axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
					const { data, status } = res.data;

					const odo = data?.odo_reading ?? "0";

					this.$set(this.dataParams, 'current_odometer', odo);
					this.data_loading.odometer = false;
				})
					.finally(fin => {
						this.data_loading.odometer = false;
					})
			}
			this.asset_no_display = this.vehicleList.data.find((x) => x.id == i);
			console.log(this.asset_no_display, i);
			console.log(this.vehicleList.data);
		},

		// var work_order_labor_request = this.dataParams.work_order_labor_request;
		// if(work_order_labor_request[i].asset_type == 'furnishing'){
		// 	var asset = this.furnishingList.data.filter((value, index)=>{
		// 		return value.asset_no == work_order_labor_request[i].no;
		// 	})
		// 	if(asset.length > 0){
		// 		work_order_labor_request[i].particular_holder = asset[0].description;


		// 		work_order_labor_request[i].brand = asset[0].mfg_brand;
		// 		work_order_labor_request[i].serial_no = asset[0].serial_no;
		// 	}
		// }
		// else if(work_order_labor_request[i].asset_type == 'equipment'){
		// 	var asset = this.equipmentList.data.filter((value, index)=>{
		// 		return value.asset_no == work_order_labor_request[i].no;
		// 	})
		// 	if(asset.length > 0){
		// 		work_order_labor_request[i].particular_holder = asset[0].description;

		// 		work_order_labor_request[i].brand = asset[0].mfg_brand;
		// 		work_order_labor_request[i].serial_no = asset[0].serial_no;
		// 	}
		// }
		// else if(work_order_labor_request[i].asset_type == 'vehicle') {
		// 	var asset = this.vehicleList.data.filter((value, index)=>{
		// 		return value.asset_no == work_order_labor_request[i].no;
		// 	})
		// 	if(asset.length > 0){
		// 		let plate_no = (asset[0].plate_no) ? asset[0].plate_no : 'N/A';
		// 		work_order_labor_request[i].particular_holder = `${asset[0].vehicle_type.setting_name} (${plate_no})` ; //vehicle type (plate no)
		// 		work_order_labor_request[i].brand = (asset[0].maker_model) ? asset[0].maker_model.setting_name : ''; // maker_model
		// 		work_order_labor_request[i].serial_no = asset[0].engine_no; //engine_number
		// 	}

		// }

		changeDesc(index) {
			this.dataParams.work_order_part_request[index].unit = this.partlist.data.filter((value) => {
				return this.dataParams.work_order_part_request[index].part_inventory_id == value.id;
			})[0].cost;
		},
		changeQty(index) {
			var part_total = parseFloat(0);
			var labor_total = parseFloat(0);

			var part_request = this.dataParams.work_order_part_request;
			for (var i = 0; i < part_request.length; i++) {
				part_total += parseFloat(part_request[i].qty * part_request[i].unit);
			}
			this.part_total = part_total.toFixed(2);

			var labor_request = this.dataParams.work_order_labor_request;
			for (var i = 0; i < labor_request.length; i++) {
				labor_total += parseFloat(labor_request[i].qty * labor_request[i].unit);
			}
			this.labor_total = labor_total.toFixed(2);
			console.log(this.part_total)
		},

		odometerChanged(ev) {
			this.odo_error = false;
			this.validating_odometer = true;
			axios.post(`${config.api_path}/odometer/validate`, {
				vehicle_id: this.dataParams.vehicle_id,
				date: this.dataParams.date,
				reading: ev,
			}).then(res => {
				const { status, message } = res.data;
				if (status == 'error') {
					let text = message;
					Swal.fire({
						title: 'Ooops!',
						text,
						icon: 'error',
					})
					this.odo_error = true;
				}
				this.validating_odometer = false;

			}).catch(err => {
				this.validating_odometer = false;
			})
		},
		dateSelected(ev) {
			this.$nextTick(() => {
				this.dataParams.date = this.dataParams.date_needed ? moment(this.dataParams.date_needed).format('MM/DD/YYYY') : null;
				if(this.dataParams.asset_type == 'vehicle')
				this.odometerChanged(this.dataParams.odo_reading)
			});
		},

	},

	computed: {
		workorderTypeFormat() {
			const opt = [];
			const newarr = this.workorder_type.concat(this.drp_options);
			newarr.forEach(element => {
				opt.push({
					'id' : element.toLowerCase(),
					'setting_name' : element
				});
			});

			return opt;
		},
		serviceTypeFormat() {
			const opt = [];
			const newarr = this.service_type.concat(this.drp_options);
			newarr.forEach(element => {
				opt.push({
					'id' : element.toLowerCase(),
					'setting_name' : element
				});
			});

			return opt;
		},
		odoHasError() {
			return this.odo_error;
		},
		// getOdometer() {
		// 	return this.dataParams.current_odometer;
		// },
		computeTotal() {

			let total = 0;
			total += this.part_total ? parseFloat(this.part_total) : 0;
			total += this.labor_total ? parseFloat(this.labor_total) : 0;
			return total;
		},

	},

	watch: {
		// 'dataParams.date_needed': function(val) {
		// 	if(val)
		//     this.odometerChanged(this.dataParams.odo_reading)
		// },


	},



}
</script>
<style>
.has-error {
	border: 0px solid red !important;
}
</style>
